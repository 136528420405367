import { GetStaticProps } from "next";
import { PrismicDocument } from "@prismicio/types";
import { getKonsolHomeNewDoc, getKonsolVenueCarouselDoc } from "lib/prismic";
import { SliceZone } from "@prismicio/react";
import { components } from "slices";
import { HeaderLayout } from "layouts/HeaderLayout";
import Head from "next/head";

type HomePageProps = {
    homeDoc: PrismicDocument;
    venueCarouselDoc: PrismicDocument;
};

const HomePage = ({
    homeDoc,
    venueCarouselDoc,
}: {
    homeDoc: PrismicDocument;
    venueCarouselDoc: PrismicDocument;
}): JSX.Element => (
    <article className="flex min-h-screen flex-col bg-blue-grey-20 bg-[url('/images/pricing_background.svg')] bg-[200%_auto] bg-repeat-y lg:bg-[120%_auto]">
        <Head>
            <title>Courtsite - Your Sports Facility Management Solution</title>
            <meta
                name="description"
                content="Courtsite is Malaysia’s leading platform connecting facilities,
                businesses, and individuals through seamless sports management.
                Enable 24/7 bookings, track business performance in real-time
                and automate operations with smart integrations."
            />
        </Head>
        <div className="relative flex w-full max-w-[1056px] flex-col px-4 pt-8 lg:mx-auto lg:pt-[84px]">
            <div className="relative flex flex-col gap-[70px] lg:gap-[100px]">
                <SliceZone
                    slices={homeDoc.data.slices}
                    components={{
                        hero_slice: components.hero_slice,
                        infographics_slice: components.infographics_slice,
                        features_slice: components.features_slice,
                        streamline_slice: components.streamline_slice,
                    }}
                    defaultComponent={components.default_slice}
                />
            </div>
        </div>
        <div className="relative flex w-full max-w-[1056px] flex-col px-4 pt-8 lg:mx-auto">
            <div className="relative flex flex-col gap-[70px] lg:gap-[100px]">
                <SliceZone
                    slices={homeDoc.data.slices}
                    components={{
                        growth_slice: components.growth_slice,
                    }}
                    defaultComponent={components.default_slice}
                />
            </div>
        </div>
        <SliceZone
            slices={homeDoc.data.slices}
            components={{
                testimonials_slice: components.testimonials_slice,
            }}
            defaultComponent={components.default_slice}
        />
        <div className="mb-[70px] lg:mb-[100px]">
            <SliceZone
                slices={venueCarouselDoc.data.slices}
                components={{
                    venue_carousel_slice: components.venue_carousel_slice,
                }}
                defaultComponent={components.default_slice}
            />
        </div>
    </article>
);

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
    const venueCarouselDoc = await getKonsolVenueCarouselDoc();
    const homeDoc = await getKonsolHomeNewDoc();
    return { props: { homeDoc, venueCarouselDoc } };
};

HomePage.Layout = HeaderLayout;
export default HomePage;
